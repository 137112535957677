import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { Element } from 'lord-icon-element/element';

const ICONS = {
    arrow_down: require('../../images/lordicon/wired-outline-33-arrow-down.json'),
    plus_minus: require('../../images/lordicon/wired-outline-381-plus-minus-morph.json'),
    lighthouse: require('../../images/lordicon/wired-gradient-2062-light-house.json'),
    rocket: require('../../images/lordicon/wired-gradient-489-rocket-space.json'),
    commandLine: require('../../images/lordicon/wired-gradient-1326-command-window-line.json'),
    webDesign: require('../../images/lordicon/wired-gradient-1328-web-design.json'),
    onlineShop: require('../../images/lordicon/wired-gradient-1359-online-shopping.json'),
    globe: require('../../images/lordicon/wired-gradient-27-globe.json'),
    mail1: require('../../images/lordicon/wired-gradient-1141-email.json'),
    mail2: require('../../images/lordicon/wired-gradient-177-envelope-mail-send.json'),
    phone1: require('../../images/lordicon/wired-gradient-23-smartphone-ring.json'),
    customerService: require('../../images/lordicon/wired-gradient-683-female-customer-service.json'),
    vector: require('../../images/lordicon/wired-gradient-117-vector-design-pen.json'),
    marketing: require('../../images/lordicon/wired-gradient-1027-marketing-campaign.json'),
    vip: require('../../images/lordicon/wired-gradient-1353-membership-vip-card.json'),
    drawingTablet: require('../../images/lordicon/wired-gradient-783-drawing-tablet.json'),
    sphere: require('../../images/lordicon/wired-gradient-1383-sphere.json'),
    organization: require('../../images/lordicon/wired-gradient-1007-organization.json'),
    workflow: require('../../images/lordicon/wired-gradient-974-process-flow-game-plan.json'),
    handshake: require('../../images/lordicon/wired-gradient-456-handshake-deal.json'),
    service: require('../../images/lordicon/wired-gradient-1029-service.json'),
    interface: require('../../images/lordicon/wired-gradient-749-interface-settings.json'),
    conference: require('../../images/lordicon/wired-gradient-969-meeting-conference-room.json'),
    employee: require('../../images/lordicon/wired-gradient-1846-employee-working.json'),
    consultation: require('../../images/lordicon/wired-gradient-981-consultation.json'),
    ufo: require('../../images/lordicon/wired-gradient-492-ufo-aliens.json'),
};

Element.setIconLoader((name) => {
    return ICONS[name];
});

class Scroll {
    element;
    targetElement;
    player;

    constructor(element, targetElement, player) {
        this.element = element;
        this.targetElement = targetElement;
        this.player = player;

        this.scroll = this.scroll.bind(this);
    }

    onReady() {
        document.addEventListener('scroll', this.scroll);

        this.scroll();
    }

    onDisconnected() {
        document.removeEventListener('scroll', this.scroll);
    }

    scroll() {
        const wy = window.scrollY;
        const p = (wy / window.innerHeight) % 1;

        this.player.frame = this.player.frames * p;
    }
}

Element.defineTrigger('scroll', Scroll);

class InScreen {
    element;
    targetElement;
    player;
    observer;

    constructor(element, targetElement, player) {
        this.element = element;
        this.targetElement = targetElement;
        this.player = player;

        const intersectionCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (!this.player.isPlaying) {
                    this.player.playFromBeginning();
                }
            });
        };

        this.observer = new IntersectionObserver(intersectionCallback, {
            threshold: 0.75,
        });
    }

    onReady() {
        this.observer.observe(this.targetElement);
    }

    onDisconnected() {
        this.observer.disconnect();
    }
}

Element.defineTrigger('in-screen', InScreen);

//animation forward on first click, reverse on second click
const CLICK_EVENTS = [
    { name: 'mousedown' },
    { name: 'touchstart', options: { passive: true } },
];

class ForwardReverse {
    element;
    targetElement;
    player;

    constructor(element, targetElement, player) {
        this.element = element;
        this.targetElement = targetElement;
        this.player = player;
        this.direction = this.reverse ? -1 : 1;
        this.onClick = this.onClick.bind(this);
    }

    onConnected() {
        for (const event of CLICK_EVENTS) {
            this.targetElement.addEventListener(
                event.name,
                this.onClick,
                event.options
            );
        }
    }

    onDisconnected() {
        for (const event of CLICK_EVENTS) {
            this.targetElement.removeEventListener(event.name, this.onClick);
        }

        this.player.direction = 1;
    }

    onReady() {
        this.player.direction = this.direction;

        if (this.reverse) {
            this.player.goToLastFrame();
        }
    }

    onComplete() {
        this.direction = -this.direction;
        this.player.direction = this.direction;
    }

    onClick() {
        if (!this.player.isPlaying) {
            this.player.play();
        }
    }

    get reverse() {
        return this.element.hasAttribute('reverse');
    }
}

Element.defineTrigger('forward-reverse', ForwardReverse);


defineElement(lottie.loadAnimation);
