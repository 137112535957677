/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

/*
 * SCSS
 */
// SD SCSS
import './styles/bundle.scss';

/*
 * JS (!! use stimulus controllers if possible; stimulus controller are being loaded automatically !!)
 *
 */
// 3rdp: Lordicon icons
import './js/lordicon/lordicon';

// SD Observer
import './js/sd/observer';

/*
 * start the Stimulus application
 */
import './bootstrap';