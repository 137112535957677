/*
* SdUxObserver v2.0
*
* using IntersectionObserver API
*
* sauber digital https://sauberdigital.de
* David Schaller
*
* */

export class SdObserver {
    constructor(observerCallback, observerOptions = {}) {
        this.observer = false;
        this.observerCallback = observerCallback;

        let root = null;
        let threshold = 0.2;
        let rootMargin = "0px 0px 0px 0px";

        if ('root' in observerOptions) {
            root = observerOptions.root;
        }

        if ('threshold' in observerOptions) {
            threshold = observerOptions.threshold;
        }

        if ('root' in observerOptions) {
            rootMargin = observerOptions.rootMargin;
        }

        this.observerOptions = {
            root: root,
            threshold: threshold,
            rootMargin: rootMargin
        }

        this.checkIfObserver();
    };

    checkIfObserver() {
        if (('IntersectionObserver' in window) ||
            ('IntersectionObserverEntry' in window) ||
            ('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {

            this.observer = new IntersectionObserver(this.observerCallback, this.observerOptions);
        }
    };

    startObserve(node) {
        this.observer.observe(node);
    };
}