import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "slide"
    ];

    slideWidth = 0;

    initialize() {

    };
};