/*
* animation controller v2.0
*
* using SdObserver
*
* sauber digital https://sauberdigital.de
* David Schaller
*
* */

/*TODO: outsource in own ux bundle with observer - > observer bundle for animation, tracking etc.*/

import { Controller } from '@hotwired/stimulus';
import { SdObserver } from '../js/sd/observer'

export default class extends Controller {
    static targets = [
        'animElement'
    ];

    static values = {
        'animationName': {
            type: String,
            default: 'anim--appear',
        },

        'animationDuration': {
            type: Number,
            default: 150,
        },

        'animationFillMode': {
            type: String,
            default: 'forwards',
        },

        'animationDirection': {
            type: String,
            default: 'normal',
        },

        'animationDelay': {
            type: Number,
            default: 200,
        },

        'animationIterationCount': {
            type: Number,
            default: 1,
        },

        'animationTimingFunction': {
            type: String,
            default: 'ease',
        },
    };

    initialize() {
        //set the delay start value for index 0
        let animationDelay = 0;

        // callback function when element(s) intersect
        // sets animation properties
        const observerCallback = (entries, observer) => {
            entries.forEach((entry, index) => {

                if (entry.isIntersecting) {
                    // index 0 starts with 0ms delay, the others add up delay time per element
                    if (index !== 0) {
                        animationDelay += this.animationDelayValue;
                    }
                    // add all animation properties
                    entry.target.style.animationDelay = animationDelay + 'ms';
                    entry.target.style.animationFillMode = this.animationFillModeValue;
                    entry.target.style.animationDirection = this.animationDirectionValue;
                    entry.target.style.animationIterationCount = this.animationIterationCountValue;
                    entry.target.style.animationTimingFunction = this.animationTimingFunctionValue;
                    entry.target.style.animationDuration = this.animationDurationValue + 'ms';

                    this.handleAnimationName(entry.target);

                    entry.target.classList.add('sd-observed');

                    observer.unobserve(entry.target);
                }
            });
        };

        const sdObserver = new SdObserver(observerCallback);

        if (sdObserver.observer !== false) {
            this.animElementTargets.forEach((target) => {
                sdObserver.startObserve(target);
            });
        } else {
            this.animElementTargets.forEach((target) => {
                target.removeAttribute('data-animation-target');
            });
        }
    };

    handleAnimationName(target) {

        if (this.animationNameValue.includes('alternating')) {
            target.classList.add('sd-observed');
        } else {
            target.style.animationName = this.animationNameValue;
        }
    };
};