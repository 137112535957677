import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'toggleElement',
        'child'
    ];

    static values= {
        'activeClass': {
            type: String,
            default: 'sd-active'
        },
    };

    connect() {
        console.log(this.childTargets)
    }

    toggle(event) {
        this.currentTarget = event.currentTarget;
        if (this.currentTarget.classList.contains(this.activeClassValue)) {
            this.removeClass();
        } else {
            this.addClass();
        }

        if (this.childTargets.length > 0) {
            this.childTargets.forEach((target) => {
                target.classList.remove(this.activeClassValue);
            });
        }
    };
    addClass() {
        this.currentTarget.classList.add(this.activeClassValue);
        this.toggleElementTarget.classList.add(this.activeClassValue);
    };

    removeClass() {
        this.currentTarget.classList.remove(this.activeClassValue);
        this.toggleElementTarget.classList.remove(this.activeClassValue);
    };
};