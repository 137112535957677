import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'panel'
    ];

    static values= {
        'activeClass': {
            type: String,
            default: 'sd-active'
        },
        'closeSiblings': {
            type: Boolean,
            default: true
        },
        'alwaysOpen': {
            type: Boolean,
            default: false
        }
    };

    initialize() {
        if (this.alwaysOpenValue === true) {
           this.panelTarget.classList.add(this.activeClassValue);
        }
    };

    toggle(event) {
        this.clickedPanel = event.srcElement.closest('[data-accordion-target="panel"]');

        if (this.closeSiblingsValue === true) {
            this.closeSiblings();
        }
        if (this.alwaysOpenValue === true) {
            if (!this.clickedPanel.classList.contains(this.activeClassValue)) {
                this.clickedPanel.classList.add(this.activeClassValue);
            }
        } else {
            if (this.clickedPanel.classList.contains(this.activeClassValue)) {
                this.clickedPanel.classList.remove(this.activeClassValue);
            } else {
                this.clickedPanel.classList.add(this.activeClassValue);
            }
        }
    };

    closeSiblings() {
        this.panelTargets.forEach(panel => {
            if (this.clickedPanel !== panel) {
                panel.classList.remove(this.activeClassValue);
            }
        });
    };
};